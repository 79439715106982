import Vue from "vue";
import { EffectList } from "../configs/DeviceList";
import DeviceControl from "./DeviceControl.vue";
import StyledButton from "./widgets/StyledButton.vue";
const KEY_NOTE_MAPPING = {
    a: "C",
    w: "C#",
    s: "D",
    e: "D#",
    d: "E",
    f: "F",
    t: "F#",
    g: "G",
    y: "G#",
    h: "A",
    u: "A#",
    j: "B",
};
export default Vue.extend({
    name: "TrackDetailPanel",
    components: {
        StyledButton,
        DeviceControl,
    },
    props: {
        track: Object,
    },
    inject: ["callMenu"],
    data() {
        return {
            keyEvent: this.onKeyEvent.bind(this),
            previewOctave: 3,
        };
    },
    mounted() {
        window.addEventListener("keydown", this.keyEvent);
    },
    destroyed() {
        window.removeEventListener("keydown", this.keyEvent);
    },
    methods: {
        handleDeleteEffect(index) {
            this.track.removeEffect(index);
        },
        handleMoveEffect({ from, to }) {
            this.track.moveEffect(from, to);
        },
        openMenu(event) {
            this.callMenu(Object.keys(EffectList), event.target, (item) => this.addEffect(item));
        },
        addEffect(item) {
            const newEffect = new EffectList[item]();
            this.track.addEffect(newEffect);
        },
        onKeyEvent(event) {
            if (event.defaultPrevented)
                return;
            if (event.key === "z") {
                this.previewOctave = Math.max(this.previewOctave - 1, -4);
            }
            else if (event.key === "x") {
                this.previewOctave = Math.min(this.previewOctave + 1, 10);
            }
            else if (Object.prototype.hasOwnProperty.call(KEY_NOTE_MAPPING, event.key)) {
                const fullNote = KEY_NOTE_MAPPING[event.key] + this.previewOctave;
                this.$emit("key-pressed", fullNote);
                this.track?.instrument.triggerAttackRelease(fullNote, "16n");
            }
        },
    },
});
