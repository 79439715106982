import Vue from "vue";
import Knob from "./widgets/Knob.vue";
import Toggle from "./widgets/Toggle.vue";
import { getAttribute, hasAttribute, isBlacklisted, } from "../configs/ParamsAttributes";
export default Vue.extend({
    name: "ParamsControl",
    components: {
        Toggle,
        Knob,
    },
    props: {
        title: String,
        root: {
            type: String,
            default: "",
        },
        path: {
            type: String,
            default: "",
        },
        options: Object,
    },
    data() {
        return {
            childPropName: undefined,
        };
    },
    computed: {
        propertyList() {
            const ret = [];
            for (let p in this.options) {
                if (!isBlacklisted(p) &&
                    typeof this.options[p] != "function" &&
                    typeof this.options[p] != "boolean" &&
                    (typeof this.options[p] != "string" ||
                        hasAttribute(this.path + "." + p))) {
                    ret.push(p);
                }
            }
            return ret;
        },
        upperItems() {
            return this.propertyList.length <= 1
                ? this.propertyList
                : this.propertyList.slice(0, (this.propertyList.length + 1) / 2);
        },
        lowerItems() {
            return this.propertyList.length <= 1
                ? []
                : this.propertyList.slice((this.propertyList.length + 1) / 2, this.propertyList.length);
        },
    },
    methods: {
        handleKnob(e) {
            this.$emit("input", this.root == ""
                ? e
                : {
                    [this.root]: e,
                });
        },
        handleToggle(e) {
            if (this.childPropName === e) {
                this.childPropName = undefined;
            }
            else {
                this.childPropName = e;
            }
        },
        getWidget(propName) {
            return typeof this.options[propName] == "object" ? Toggle : Knob;
        },
        getParamAttributes(fullPropName) {
            return getAttribute(fullPropName);
        },
    },
});
