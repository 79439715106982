import Vue from "vue";
import { SHLMonoSampler } from "../SHLTone/SHLMonoSampler";
import { SampleList } from "../configs/SampleList";
import StyledButton from "./widgets/StyledButton.vue";
import { map } from "../utils/SHLMath";
export default Vue.extend({
    name: "SHLMonoSamplerCustomControl",
    components: { StyledButton },
    props: {
        device: SHLMonoSampler,
    },
    inject: ["callMenu"],
    data() {
        return {
            isSampleLoaded: this.device.sampleBuffer.loaded,
        };
    },
    mounted() {
        this.drawCanvas();
    },
    methods: {
        openSampleMenu(event) {
            this.callMenu(Object.keys(SampleList), event.target, this.onSampleMenuItemSelected.bind(this));
        },
        onSampleMenuItemSelected(item) {
            this.device.add("A1", SampleList[item], this.drawCanvas.bind(this), item);
            this.isSampleLoaded = false;
        },
        onSampleDisplayClicked() {
            this.device.triggerAttackRelease("A1", "16n");
        },
        drawCanvas() {
            if (!this.device.sampleBuffer.loaded) {
                setTimeout(() => this.drawCanvas(), 200);
                return;
            }
            this.isSampleLoaded = true;
            const context = this.$refs.displayCanvas.getContext("2d");
            context.clearRect(0, 0, 480, 100);
            const bufferData = this.device.sampleBuffer.toArray(0);
            context.beginPath();
            context.moveTo(0, 50);
            const indexIncrement = Math.floor(bufferData.length / 480);
            for (let i = 1; i <= 480; i++) {
                context.lineTo(i, map(bufferData[(i - 1) * indexIncrement], -1, 1, 0, 100));
            }
            context.strokeStyle = "#f80";
            context.lineWidth = 2;
            context.stroke();
        },
    },
    computed: {},
});
