var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "piano-main", style: _vm.mainStyle },
    [
      _c(
        "div",
        {
          staticClass: "piano-octave-control piano-octave-control-lower",
          on: {
            click: function($event) {
              return _vm.changeOctave(-1)
            }
          }
        },
        [_c("span", [_vm._v("←")])]
      ),
      _vm._v(" "),
      _vm._l(_vm.extendedNotes, function(extendedNote) {
        return _c(
          "div",
          {
            key: _vm.currentOctave + extendedNote.fullName,
            staticClass: "piano-key",
            class: {
              "piano-key-black": _vm.isBlackKey(extendedNote.name),
              "piano-key-selected": extendedNote.fullName === _vm.selectedNote,
              "piano-key-pressed": extendedNote.fullName === _vm.pressedKey
            },
            on: {
              mousedown: function($event) {
                return _vm.handleClickOnNote($event, extendedNote.fullName)
              },
              contextmenu: function(e) {
                return e.preventDefault()
              },
              mouseenter: function($event) {
                return _vm.handleEnterOnNote($event, extendedNote.fullName)
              }
            }
          },
          [_c("span", [_vm._v(_vm._s(extendedNote.fullName))])]
        )
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "piano-octave-control piano-octave-control-upper",
          on: {
            click: function($event) {
              return _vm.changeOctave(1)
            }
          }
        },
        [_c("span", [_vm._v("→")])]
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }