var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "track-main",
      class: { "track-active": _vm.selected },
      on: { click: _vm.onTrackFocus, contextmenu: _vm.showContextMenu }
    },
    [
      _c(
        "div",
        { staticClass: "track-left" },
        [
          _c(
            "StyledButton",
            {
              staticClass: "track-delete-button",
              on: {
                click: function($event) {
                  return _vm.$emit("track-delete", _vm.track.uid)
                }
              }
            },
            [_vm._v("X")]
          ),
          _vm._v(" "),
          _vm.isEditingName
            ? _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.track.name,
                    expression: "track.name"
                  }
                ],
                ref: "nameInput",
                staticClass: "track-name",
                domProps: { value: _vm.track.name },
                on: {
                  keydown: function(e) {
                    return e.stopPropagation()
                  },
                  blur: function($event) {
                    _vm.isEditingName = false
                  },
                  change: _vm.handleNameChange,
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.track, "name", $event.target.value)
                  }
                }
              })
            : _c(
                "div",
                {
                  staticClass: "track-name",
                  on: { dblclick: _vm.startEditName }
                },
                [_vm._v("\n      " + _vm._s(_vm.track.name) + "\n    ")]
              ),
          _vm._v(" "),
          _c("ChannelControl", {
            staticClass: "channel-control",
            attrs: { channel: _vm.channelParams },
            on: {
              "update:channel": function($event) {
                return _vm.track.channel.set($event)
              }
            }
          }),
          _vm._v(" "),
          _c("MeterVisualizer", { attrs: { meter: _vm.track.meter } })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "track-right" },
        _vm._l(_vm.track.sequenceData, function(_, index) {
          return _c(
            "SequencerCell",
            _vm._g(
              {
                key: index,
                staticClass: "track-seq-cell",
                class: {
                  "timeslot-input-active": _vm.track.currentStep === index
                },
                attrs: {
                  mono: _vm.track.instrument.name === "SHLMonoSampler",
                  instrument: _vm.track.instrument
                },
                on: { update: _vm.updateSequence },
                model: {
                  value: _vm.track.sequenceData[index],
                  callback: function($$v) {
                    _vm.$set(_vm.track.sequenceData, index, $$v)
                  },
                  expression: "track.sequenceData[index]"
                }
              },
              _vm.$listeners
            )
          )
        }),
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }