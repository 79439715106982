var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "seq-cell-main",
      class: {
        "seq-cell-filled": _vm.value !== "",
        "seq-cell-focused": _vm.isFocused
      },
      on: { click: _vm.handleLeftClick, contextmenu: _vm.handleRightClick }
    },
    [
      !_vm.mono
        ? _c("span", { staticClass: "seq-cell-note" }, [
            _vm._v("\n    " + _vm._s(_vm.value) + "\n  ")
          ])
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }