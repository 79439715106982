import Vue from "vue";
export default Vue.extend({
    name: "ChannelControl",
    props: {
        channel: Object,
    },
    watch: {
        channel() {
            this.channelParams = this.channel;
        },
    },
    data() {
        return {
            channelParams: { ...this.channel },
        };
    },
    methods: {
        emitData() {
            this.$emit("update:channel", this.channelParams);
        },
        toggleSolo() {
            this.channelParams.solo = !this.channelParams.solo;
            this.emitData();
        },
        toggleMute() {
            this.channelParams.mute = !this.channelParams.mute;
            this.emitData();
        },
    },
});
