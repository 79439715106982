var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "menu-main" }, [
    _c(
      "div",
      {
        staticClass: "menu-backdrop",
        on: {
          click: function($event) {
            return _vm.$emit("dismiss-menu")
          }
        }
      },
      [
        _c(
          "div",
          {
            ref: "menuEl",
            staticClass: "menu-item-container",
            style: _vm.itemContainerStyle
          },
          _vm._l(_vm.items, function(item) {
            return _c(
              "div",
              {
                key: item,
                staticClass: "menu-item",
                on: {
                  click: function($event) {
                    return _vm.$emit("select-menu-item", item)
                  }
                }
              },
              [_vm._v("\n        " + _vm._s(item) + "\n      ")]
            )
          }),
          0
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }