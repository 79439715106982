var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "cc-main" } }, [
    _c("div", { staticClass: "slider" }, [
      _c("span", { staticClass: "slider-tag" }, [_vm._v("Gain")]),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.channelParams.volume,
            expression: "channelParams.volume"
          }
        ],
        attrs: { type: "range", min: "-60", max: "0", step: "0.1" },
        domProps: { value: _vm.channelParams.volume },
        on: {
          input: _vm.emitData,
          __r: function($event) {
            return _vm.$set(_vm.channelParams, "volume", $event.target.value)
          }
        }
      }),
      _vm._v(" "),
      _c("span", { staticClass: "slider-value" }, [
        _vm._v(_vm._s(Number(_vm.channelParams.volume).toFixed(2)) + "db")
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "slider" }, [
      _c("span", { staticClass: "slider-tag" }, [_vm._v("Pan")]),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.channelParams.pan,
            expression: "channelParams.pan"
          }
        ],
        attrs: { type: "range", min: "-1", max: "1", step: "0.1" },
        domProps: { value: _vm.channelParams.pan },
        on: {
          input: _vm.emitData,
          __r: function($event) {
            return _vm.$set(_vm.channelParams, "pan", $event.target.value)
          }
        }
      }),
      _vm._v(" "),
      _c("span", { staticClass: "slider-value" }, [
        _vm._v(_vm._s(Number(_vm.channelParams.pan).toFixed(1)))
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "button-area" }, [
      _c("div", {
        staticClass: "button button-solo",
        class: { "button-active": _vm.channelParams.solo },
        on: { click: _vm.toggleSolo }
      }),
      _vm._v(" "),
      _c("div", {
        staticClass: "button button-mute",
        class: { "button-active": _vm.channelParams.mute },
        on: { click: _vm.toggleMute }
      })
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }