import Vue from "vue";
import { clamp } from "../../utils/SHLMath";
const OCTAVE_RANGE = {
    min: -4,
    max: 11,
};
const NOTE_NAME = [
    "C",
    "C#",
    "D",
    "D#",
    "E",
    "F",
    "F#",
    "G",
    "G#",
    "A",
    "A#",
    "B",
];
const PIANO_DIMENSIONS = {
    width: 1080,
    height: 160,
};
const PIANO_OFFSET = {
    x: 0,
    y: 40,
};
function calcFixedPosition({ x, y }) {
    x =
        clamp(x + PIANO_OFFSET.x, PIANO_DIMENSIONS.width / 2, window.innerWidth - PIANO_DIMENSIONS.width / 2) -
            PIANO_DIMENSIONS.width / 2;
    y = clamp(y + PIANO_OFFSET.y, 0, window.innerHeight - PIANO_DIMENSIONS.height);
    return { x, y };
}
function extractScientificNoteName(note) {
    if (note === "")
        return { name: "C", oct: 3 }; // empty note: return default
    const numStart = note.charAt(1) == "#" ? 2 : 1;
    return {
        name: note.slice(0, numStart),
        oct: +note.slice(numStart),
    };
}
export default Vue.extend({
    name: "Piano",
    props: {
        pianoContext: Object,
        pressedKey: String,
    },
    watch: {
        pianoContext(_, oldContext) {
            oldContext?.onCancel?.();
            this.selectedNote = this.pianoContext.currentNote;
            this.lookAtSelectedNote();
        },
    },
    mounted() {
        window.addEventListener("click", this.dismissListener);
        window.addEventListener("contextmenu", this.dismissListener);
        this.lookAtSelectedNote();
    },
    destroyed() {
        window.removeEventListener("click", this.dismissListener);
        window.removeEventListener("contextmenu", this.dismissListener);
    },
    data() {
        return {
            currentOctave: 2,
            dismissListener: (event) => {
                if (event != this.pianoContext.activatingMouseEvent &&
                    event.target.closest(".piano-main") != this.$el) {
                    this.pianoContext.onCancel?.();
                    this.$emit("dismiss");
                }
            },
            selectedNote: this.pianoContext.currentNote,
        };
    },
    computed: {
        extendedNotes() {
            const OCTAVE_LENGTH = 3;
            const ret = [];
            for (let oct = this.currentOctave; oct < this.currentOctave + OCTAVE_LENGTH; oct++) {
                for (let noteIndex = 0; noteIndex < 12; noteIndex++) {
                    ret.push({
                        name: NOTE_NAME[noteIndex],
                        oct: oct,
                        fullName: NOTE_NAME[noteIndex] + oct,
                    });
                }
            }
            return ret;
        },
        mainStyle() {
            const { x, y } = calcFixedPosition(this.pianoContext.activatingMouseEvent);
            return {
                width: PIANO_DIMENSIONS.width + "px",
                height: PIANO_DIMENSIONS.height + "px",
                left: x + "px",
                top: y + "px",
            };
        },
    },
    methods: {
        isBlackKey(name) {
            return typeof name == "string" && name.length == 2;
        },
        handleClickOnNote(event, note) {
            event.preventDefault();
            if (event.buttons & 1) {
                this.pianoContext.onSelected(note);
                this.selectedNote = note;
            }
            this.pianoContext.instrument.triggerAttackRelease(note, "16n");
        },
        handleEnterOnNote(event, note) {
            if (event.buttons != 0) {
                this.handleClickOnNote(event, note);
            }
        },
        changeOctave(delta) {
            this.setOctave(this.currentOctave + delta);
        },
        setOctave(newOct) {
            this.currentOctave = clamp(newOct, OCTAVE_RANGE.min, OCTAVE_RANGE.max);
        },
        lookAtSelectedNote() {
            const { oct } = extractScientificNoteName(this.selectedNote);
            this.setOctave(oct - 1);
        },
    },
});
