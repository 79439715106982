/* eslint-disable prefer-rest-params */
import { MonoSynth, Signal, Multiply } from "tone";
import { Monophonic, } from "tone/build/esm/instrument/Monophonic";
import { readOnly } from "tone/build/esm/core/util/Interface";
import { deepMerge, omitFromObject, optionsFromArguments, } from "tone/build/esm/core/util/Defaults";
export class SHLSynth extends Monophonic {
    constructor() {
        super(optionsFromArguments(SHLSynth.getDefaults(), arguments));
        this.name = "SHLSynth";
        this.detune = new Signal(0);
        const options = optionsFromArguments(SHLSynth.getDefaults(), arguments);
        this.detune.value = options.detune;
        this.voice0 = new MonoSynth(Object.assign(options.voice0, {
            context: this.context,
            onsilence: () => this.onsilence(this),
        }));
        this.voice1 = new MonoSynth(Object.assign(options.voice1, {
            context: this.context,
        }));
        this.voice2 = new MonoSynth(Object.assign(options.voice2, {
            context: this.context,
        }));
        this.harmonicity1 = new Multiply({
            context: this.context,
            units: "positive",
            value: options.harmonicity1,
        });
        this.harmonicity2 = new Multiply({
            context: this.context,
            units: "positive",
            value: options.harmonicity2,
        });
        this.frequency = new Signal({
            context: this.context,
            units: "frequency",
            value: 440,
        });
        this.frequency.connect(this.voice0.frequency);
        this.frequency.chain(this.harmonicity1, this.voice1.frequency);
        this.frequency.chain(this.harmonicity2, this.voice2.frequency);
        this.detune.fan(this.voice0.detune, this.voice1.detune, this.voice2.detune);
        this.voice0.connect(this.output);
        this.voice1.connect(this.output);
        this.voice2.connect(this.output);
        readOnly(this, ["voice0", "voice1", "voice2", "frequency"]);
    }
    _triggerEnvelopeAttack(time, velocity) {
        this.voice0.triggerAttack(this.frequency.value, time, velocity);
        this.voice1.triggerAttack(this.frequency.value, time, velocity);
        this.voice2.triggerAttack(this.frequency.value, time, velocity);
    }
    _triggerEnvelopeRelease(time) {
        this.voice0.triggerRelease(time);
        this.voice1.triggerRelease(time);
        this.voice2.triggerRelease(time);
    }
    getLevelAtTime(time) {
        time = this.toSeconds(time);
        return (this.voice0.envelope.getValueAtTime(time) +
            this.voice1.envelope.getValueAtTime(time) +
            this.voice2.envelope.getValueAtTime(time));
    }
    static getDefaults() {
        const defaultMonoSynthOptions = {
            filterEnvelope: {
                attack: 0.05,
                decay: 0.0,
                sustain: 1,
                release: 0.5,
            },
            envelope: {
                attack: 0.05,
                decay: 0.0,
                sustain: 1,
                release: 0.5,
            },
        };
        return deepMerge(Monophonic.getDefaults(), {
            harmonicity1: 2,
            harmonicity2: 0.5,
            voice0: deepMerge(omitFromObject(MonoSynth.getDefaults(), Object.keys(Monophonic.getDefaults())), defaultMonoSynthOptions),
            voice1: deepMerge(MonoSynth.getDefaults(), defaultMonoSynthOptions),
            voice2: deepMerge(MonoSynth.getDefaults(), defaultMonoSynthOptions),
        });
    }
    dispose() {
        super.dispose();
        this.voice0.dispose();
        this.voice1.dispose();
        this.voice2.dispose();
        this.frequency.dispose();
        this.detune.dispose();
        this.harmonicity1.dispose();
        this.harmonicity2.dispose();
        return this;
    }
}
