import { SHLSynth } from "../SHLTone/SHLSynth";
import { SHLMonoSampler } from "../SHLTone/SHLMonoSampler";
import {
  AutoFilter,
  AutoPanner,
  Chebyshev,
  Chorus,
  Distortion,
  FeedbackDelay,
  MonoSynth,
  Phaser,
  PingPongDelay,
  PolySynth,
  Reverb,
  StereoWidener,
  PitchShift,
  FrequencyShifter,
} from "tone";
import { SHLMixingUtilities } from "../SHLTone/effects/SHLMixingUtilities";

export const InstrumentList = {
  SHLSynth: SHLSynth,
  SHLMonoSampler: SHLMonoSampler,
  MonoSynth: MonoSynth,
  PolySynth: PolySynth,
};

export const EffectList = {
  Chorus: Chorus,
  Reverb: Reverb,
  Distortion: Distortion,
  PitchShift: PitchShift,
  FrequencyShifter: FrequencyShifter,
  AutoFilter: AutoFilter,
  AutoPanner: AutoPanner,
  PingPongDelay: PingPongDelay,
  Phaser: Phaser,
  FeedbackDelay: FeedbackDelay,
  Chebyshev: Chebyshev,
  SHLMixingUtilities: SHLMixingUtilities,
  StereoWidener: StereoWidener,
};
