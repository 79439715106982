import Vue from "vue";
import { TrackOptions, SHLTrack } from "../SHLTone/SHLTrack";
import ChannelControl from "./ChannelControl.vue";
import SequencerCell from "./SequencerCell.vue";
import MeterVisualizer from "./MeterVisualizer.vue";
import StyledButton from "./widgets/StyledButton.vue";
export default Vue.extend({
    name: "Track",
    components: { StyledButton, MeterVisualizer, SequencerCell, ChannelControl },
    inject: [
        "callMenu",
        "getClipboard",
        "setClipboard",
        "addTrackAsNew",
        "requestNewTrackUid",
    ],
    props: {
        trackOptions: TrackOptions,
        selected: Boolean,
    },
    data() {
        return {
            track: new SHLTrack(),
            isEditingName: false,
            lastName: "",
            contextActions: {
                "Copy Track": {
                    predicate: () => true,
                    action: this.copyTrack.bind(this),
                },
                "Copy Sequence": {
                    predicate: () => true,
                    action: this.copySequence.bind(this),
                },
                "Paste Track (Overwrite)": {
                    predicate: (clipboard) => clipboard != null && clipboard.type === "track",
                    action: this.pasteTrack.bind(this),
                },
                "Paste Track As New": {
                    predicate: (clipboard) => clipboard != null && clipboard.type === "track",
                    action: this.pasteTrackAsNew.bind(this),
                },
                "Paste Sequence": {
                    predicate: (clipboard) => clipboard != null && clipboard.type === "sequence",
                    action: this.pasteSequence.bind(this),
                },
            },
        };
    },
    methods: {
        updateSequence() {
            this.track.reloadSequence();
        },
        onTrackFocus() {
            this.$emit("track-focus", {
                track: this.track,
                uid: this.trackOptions.uid,
            });
        },
        startEditName() {
            this.lastName = this.track.name;
            this.isEditingName = true;
            this.$nextTick().then(() => this.$refs.nameInput.focus());
        },
        handleNameChange() {
            if (this.track.name === "") {
                this.track.name = this.lastName;
            }
            this.isEditingName = false;
        },
        showContextMenu(event) {
            this.onTrackFocus();
            if (event.defaultPrevented)
                return;
            event.preventDefault();
            this.callMenu(Object.keys(this.contextActions).filter((key) => this.contextActions[key].predicate(this.getClipboard())), event, (item) => {
                this.contextActions[item].action();
            });
        },
        copySequence() {
            this.setClipboard({
                type: "sequence",
                data: [...this.track.sequenceData],
            });
        },
        pasteSequence() {
            this.track.sequenceData = this.getClipboard().data;
            this.updateSequence();
        },
        copyTrack() {
            this.setClipboard({
                type: "track",
                data: this.track.toOptions(),
            });
        },
        pasteTrack() {
            this.track.fromOptions(this.getClipboard().data);
            this.track.uid = this.requestNewTrackUid();
        },
        pasteTrackAsNew() {
            this.addTrackAsNew(this.getClipboard().data);
        },
    },
    computed: {
        channelParams() {
            return {
                volume: this.track.channel.volume.value,
                pan: this.track.channel.pan.value,
                solo: this.track.channel.solo,
                mute: this.track.channel.mute,
            };
        },
    },
    created() {
        this.track.fromOptions(this.trackOptions);
        this.$emit("track-created", { track: this.track, uid: this.track.uid });
    },
    destroyed() {
        this.track?.dispose();
    },
});
