const filterEnvelope = {
  attack: 0.01,
  decay: 0.3,
  sustain: 0,
  release: 2,
};

const envelope = {
  attack: 0.01,
  decay: 0.3,
  sustain: 0,
  release: 2,
};

export default {
  harmonicity1: 4,
  harmonicity2: 2,
  voice0: {
    oscillator: {
      type: "square",
    },
    filterEnvelope: {
      ...filterEnvelope,
      baseFrequency: 400,
      octaves: 4,
    },
    envelope: envelope,
  },
  voice1: {
    oscillator: {
      type: "square",
    },
    filterEnvelope: {
      ...filterEnvelope,
      baseFrequency: 600,
      octaves: 8,
    },
    envelope: envelope,
  },
  voice2: {
    oscillator: {
      type: "triangle",
    },
    filterEnvelope: {
      ...filterEnvelope,
      baseFrequency: 200,
      octaves: 4,
    },
    envelope: envelope,
  },
};
