import Vue from "vue";
import { clamp } from "../utils/SHLMath";
export default Vue.extend({
    name: "MeterVisualizer",
    props: {
        meter: Object,
    },
    data() {
        return {
            fillRatio: 0.5,
        };
    },
    mounted() {
        this.renderLoop();
    },
    methods: {
        renderLoop() {
            if (!this.meter)
                return;
            window.requestAnimationFrame(this.renderLoop.bind(this));
            const meterVal = this.meter.getValue();
            this.fillRatio = clamp(meterVal, 0, 1);
        },
        log() {
            console.log(this.meter.getValue());
        },
    },
    computed: {
        fillStyle() {
            return {
                transform: `scaleY(${this.fillRatio})`,
            };
        },
    },
});
