export function deg2rad(deg) {
    return (Math.PI * deg) / 180;
}
export function map(val, x1, y1, x2, y2) {
    return lerp(normalize(val, x1, y1), x2, y2);
}
export function lerp(val, x, y) {
    return val * (y - x) + x;
}
export function logLerp(val, x, y) {
    if (x === 0)
        x = 0.001; // avoid NaN on 0
    return x * (y / x) ** val;
}
export function clamp(val, x, y) {
    return Math.max(x, Math.min(val, y));
}
export function normalize(val, x, y) {
    return (clamp(val, x, y) - x) / (y - x);
}
export function logNormalize(val, x, y) {
    if (x == 0)
        x = 0.001; // avoid NaN on 0
    return Math.log(clamp(val, x, y) / x) / Math.log(y / x);
}
