import Vue from "vue";
import { ToneWithContext } from "tone/build/esm/core/context/ToneWithContext";
import ParamsControl from "./ParamsControl.vue";
import { CustomDeviceComponents } from "../configs/CustomDeviceComponents";
import PlayIcon from "../assets/icons/play.svg";
import PlayInvertIcon from "../assets/icons/playInvert.svg";
export default Vue.extend({
    name: "DeviceControl",
    components: {
        ParamsControl,
    },
    props: {
        device: ToneWithContext,
        deviceIndex: Number,
        totalDeviceCount: Number,
    },
    data() {
        return {
            options: this.device.get(),
            showDebug: false,
            icons: {
                playIcon: PlayIcon,
                playInvertIcon: PlayInvertIcon,
            },
        };
    },
    watch: {
        device() {
            this.options = this.device.get();
        },
    },
    computed: {
        customComponent() {
            return CustomDeviceComponents[this.device.name];
        },
    },
    methods: {
        handleInput(e) {
            this.device.set(e);
            this.options = this.device.get();
        },
    },
});
