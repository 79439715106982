var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "daw-main", on: { click: _vm.initAudio } },
    [
      _c("div", { staticClass: "global-control" }, [
        _c(
          "div",
          { staticClass: "transport-control" },
          [
            _c("div"),
            _vm._v(" "),
            _c(
              "StyledButton",
              {
                staticClass: "play-button",
                on: { click: _vm.toggleTransport }
              },
              [
                _c("img", {
                  attrs: {
                    src: _vm.isTransportPlaying
                      ? _vm.icons.stop
                      : _vm.icons.play,
                    alt: "play icon"
                  }
                })
              ]
            ),
            _vm._v(" "),
            _c(
              "StyledButton",
              { staticClass: "play-button", on: { click: _vm.toggleRecord } },
              [
                _c("img", {
                  attrs: {
                    src: _vm.isRecording
                      ? _vm.icons.recordInvert
                      : _vm.icons.record,
                    alt: "record icon"
                  }
                })
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "bpm-setting" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.bpm,
                    expression: "bpm"
                  }
                ],
                staticClass: "bpm-input",
                domProps: { value: _vm.bpm },
                on: {
                  change: _vm.setBpm,
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.bpm = $event.target.value
                  }
                }
              }),
              _vm._v("BPM\n      ")
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "swing-setting" }, [
              _c("div", [_vm._v("swing amount")]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.transport.swing,
                    expression: "transport.swing"
                  }
                ],
                attrs: { type: "range", min: "0", max: "1", step: "0.05" },
                domProps: { value: _vm.transport.swing },
                on: {
                  __r: function($event) {
                    return _vm.$set(_vm.transport, "swing", $event.target.value)
                  }
                }
              })
            ])
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "project-control" },
          [
            _c("StyledButton", { on: { click: _vm.importProject } }, [
              _vm._v("import")
            ]),
            _vm._v(" "),
            _c("StyledButton", { on: { click: _vm.exportProject } }, [
              _vm._v("export")
            ]),
            _vm._v(" "),
            _c("StyledButton", { on: { click: _vm.clearProject } }, [
              _vm._v("clear")
            ])
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "track-container" },
        [
          _vm._l(_vm.projectData, function(trackRecord) {
            return _c("Track", {
              key: trackRecord.initOptions.uid,
              attrs: {
                "track-options": trackRecord.initOptions,
                selected:
                  _vm.selectedTrackInfo &&
                  _vm.selectedTrackInfo.uid === trackRecord.initOptions.uid
              },
              on: {
                "evoke-piano": _vm.evokePiano,
                "track-focus": _vm.onTrackFocus,
                "track-created": _vm.onTrackCreated,
                "track-delete": _vm.onTrackDelete
              }
            })
          }),
          _vm._v(" "),
          _c(
            "StyledButton",
            {
              staticClass: "add-new-track-button",
              on: { click: _vm.openAddNewTrackMenu }
            },
            [_vm._v("Add new track")]
          )
        ],
        2
      ),
      _vm._v(" "),
      _c("div", { attrs: { id: "track-panel" } }),
      _vm._v(" "),
      _vm.selectedTrackInfo
        ? _c("TrackDetailPanel", {
            attrs: { track: _vm.selectedTrackInfo.track },
            on: {
              "key-pressed": function($event) {
                _vm.pressedKey = $event
              }
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.pianoContext
        ? _c("Piano", {
            attrs: {
              "piano-context": _vm.pianoContext,
              "pressed-key": _vm.pressedKey
            },
            on: { dismiss: _vm.dismissPiano }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }