import Kick707 from "../assets/samples/707/kick.wav";
import Snare707 from "../assets/samples/707/snare.wav";
import Ride707 from "../assets/samples/707/ride.wav";
import HatClosed707 from "../assets/samples/707/hat_closed.wav";
import HatOpen707 from "../assets/samples/707/hat_open.wav";

export const SampleList = {
  "707/Kick": Kick707,
  "707/Snare": Snare707,
  "707/Ride": Ride707,
  "707/Hat Closed": HatClosed707,
  "707/Hat Open": HatOpen707,
};
