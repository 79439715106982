export const LeadSequence = [
  "F#3",
  "F#3",
  "D3",
  "B2",
  "",
  "B2",
  "",
  "E3",
  "",
  "E3",
  "",
  "E3",
  "G#3",
  "G#3",
  "A3",
  "B3",
  "A3",
  "A3",
  "A3",
  "E3",
  "",
  "D3",
  "",
  "F#3",
  "",
  "F#3",
  "",
  "F#3",
  "E3",
  "E3",
  "F#3",
  "E3",
];

export const KickSequence = [
  "C1",
  "C1",
  "",
  "C1",
  "",
  "",
  "",
  "C1",
  "",
  "C1",
  "",
  "",
  "C1",
  "C1",
  "",
  "",
  "C1",
  "",
  "",
  "C1",
  "",
  "",
  "",
  "C1",
  "",
  "C1",
  "",
  "",
  "C1",
  "",
  "",
  "",
];
