var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "app-main" } },
    [
      _c("WebDAW"),
      _vm._v(" "),
      _vm.isMenuOpen
        ? _c("Menu", {
            attrs: { items: _vm.menuItems, anchor: _vm.menuAnchor },
            on: {
              "dismiss-menu": _vm.onDismiss,
              "select-menu-item": _vm.onMenuSelected
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }