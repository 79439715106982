import { Channel, Meter, Mono, Sequence, } from "tone";
import { InstrumentList, EffectList } from "../configs/DeviceList";
export class SHLTrack {
    constructor() {
        this.name = "";
        this.uid = -255;
        this.effectUniqueId = 0;
        this.currentStep = -1;
        this.effects = [];
        this.sequenceData = [];
        this.sequenceInstance = this.reloadSequence();
        this.channel = new Channel({ channelCount: 2 }).toDestination();
        this.meter = new Meter({
            normalRange: true,
        });
        this._mono = new Mono().connect(this.meter);
        this.channel.connect(this._mono);
    }
    _assignUidToEffect(effect) {
        return Object.defineProperty(effect, "uniqueId", {
            value: this.effectUniqueId++,
            configurable: true,
        });
    }
    addEffect(effect) {
        if (this.effects.length != 0) {
            this.effects[this.effects.length - 1]
                .disconnect(this.channel)
                .connect(effect);
        }
        else {
            this.instrument?.disconnect(this.channel).connect(effect);
        }
        effect.connect(this.channel);
        const converted = this._assignUidToEffect(effect);
        this.effects.push(converted);
        converted.start?.();
        return this;
    }
    moveEffect(from, to) {
        if (from >= this.effects.length || to >= this.effects.length)
            return this;
        const extracted = this.removeEffect(from, false);
        if (!extracted)
            return this;
        this.insertEffectAt(extracted, to);
        return this;
    }
    insertEffectAt(effect, index) {
        if (index > this.effects.length)
            return this;
        if (index == this.effects.length)
            return this.addEffect(effect);
        // at least 1 effect
        if (index == 0) {
            this.instrument?.disconnect(this.effects[0]).connect(effect);
        }
        else {
            this.effects[index - 1].disconnect(this.effects[index]).connect(effect);
        }
        effect.connect(this.effects[index]);
        this.effects.splice(index, 0, effect);
        return this;
    }
    disposeEffect(effect) {
        // needed because setting 'writable' to property sets the getter/setter to undefined
        // see Filter.ts
        try {
            effect.dispose();
        }
        catch (e) {
            console.log(e);
        }
    }
    removeEffect(index, dispose = true) {
        if (index < 0 || this.effects.length <= index)
            return null;
        const target = this.effects[index];
        target.disconnect();
        if (index == 0) {
            this.instrument
                ?.disconnect(target)
                .connect(this.effects.length > 1 ? this.effects[1] : this.channel);
        }
        else if (index == this.effects.length - 1) {
            // effects.length > 1
            this.effects[index - 1].disconnect(target).connect(this.channel);
        }
        else {
            this.effects[index - 1]
                .disconnect(target)
                .connect(this.effects[index + 1]);
        }
        if (dispose)
            this.disposeEffect(target);
        this.effects.splice(index, 1);
        return target;
    }
    setInstrument(instrument) {
        this.instrument?.dispose();
        this.instrument = instrument;
        if (this.effects.length == 0) {
            this.instrument?.connect(this.channel);
        }
        else {
            this.instrument?.connect(this.effects[0]);
        }
        return this.instrument;
    }
    setSequenceData(seqData) {
        const newSequence = seqData ?? [];
        newSequence.length = 32;
        for (let i = 0; i < 32; i++) {
            if (newSequence[i] == undefined) {
                newSequence[i] = "";
            }
        }
        this.sequenceData = [...newSequence];
        this.reloadSequence();
        return this.sequenceData;
    }
    dispose() {
        this.instrument?.dispose();
        this.sequenceInstance?.dispose();
        this._mono?.dispose();
        this.meter?.dispose();
        this.channel?.dispose();
        this.effects.forEach((e) => this.disposeEffect(e));
        return this;
    }
    reloadSequence() {
        this.sequenceInstance?.dispose();
        return (this.sequenceInstance = new Sequence({
            callback: this.onNoteTrigger.bind(this),
            events: this.sequenceData,
            loop: true,
            subdivision: "16n",
        }).start(0));
    }
    toOptions() {
        const channelProp = this.channel.get();
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        delete channelProp.channelCount; // necessary hack for tone.js bug
        return {
            name: this.name,
            uid: this.uid,
            channel: channelProp,
            instrument: {
                name: this.instrument?.name,
                data: this.instrument?.get(),
            },
            effects: this.effects.map((effect) => ({ name: effect.name, data: effect.get() })),
            sequence: this.sequenceData,
        };
    }
    fromOptions(data) {
        this.name = data.name;
        this.uid = data.uid;
        if (data.channel != undefined)
            this.channel.set(data.channel);
        this.setSequenceData(data.sequence);
        this.instrument?.dispose();
        this.setInstrument(new InstrumentList[data.instrument.name](data.instrument.data));
        this.effects.forEach((e) => this.disposeEffect(e));
        this.effects.length = 0;
        data.effects?.forEach((effectOption) => this.addEffect(new EffectList[effectOption.name](effectOption.data)));
        return this;
    }
    onNoteTrigger(time, note) {
        this.currentStep = Math.floor(this.sequenceInstance.progress * this.sequenceData.length);
        if (note === "")
            return;
        this.instrument?.triggerAttackRelease(note, "16n", time);
    }
}
