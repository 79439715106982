const ADSRAttr = {
  min: 0.01,
  max: 20,
  unit: "s",
};

const normalAttr = {
  min: 0,
  max: 1,
};

const filterTypeAttr = {
  type: "enum",
  values: [
    "lowpass",
    "highpass",
    "bandpass",
    "lowshelf",
    "highshelf",
    "notch",
    "allpass",
    "peaking",
  ],
};

const oscillatorTypeAttr = {
  type: "enum",
  values: ["sine", "square", "sawtooth", "triangle"],
};

const frequencyAttr = {
  min: 20,
  max: 20000,
  type: "log",
  unit: "Hz",
};

const lfoFrequencyAttr = {
  min: 0.001,
  max: 20000,
  type: "log",
  unit: "Hz",
};

const decibelAttr = {
  min: -60,
  max: 36,
  unit: "db",
};

const multiplierAttr = {
  min: 0,
  max: 32,
};

export const ParamsAttributes = {
  attack: ADSRAttr,
  decay: ADSRAttr,
  sustain: normalAttr,
  release: ADSRAttr,
  frequency: frequencyAttr,
  baseFrequency: frequencyAttr,
  lowFrequency: frequencyAttr,
  highFrequency: frequencyAttr,
  gain: decibelAttr,
  high: decibelAttr,
  mid: decibelAttr,
  low: decibelAttr,
  harmonicity: multiplierAttr,
  harmonicity1: multiplierAttr,
  harmonicity2: multiplierAttr,
  volume: decibelAttr,
  octaves: multiplierAttr,
  order: {
    type: "int",
    min: 1,
    max: 100,
  },
  detune: {
    min: -100,
    max: 100,
  },
  Q: {
    min: 0.01,
    max: 4,
  },
  delayTime: {
    min: 0.01,
    max: 20,
  },
  ratio: {
    min: 1,
    max: 20,
  },
  threshold: {
    min: -100,
    max: 0,
  },
  channelCount: {
    min: 1,
    max: 2,
  },
  AutoFilter: {
    frequency: lfoFrequencyAttr,
    type: oscillatorTypeAttr,
  },
  Chorus: {
    frequency: lfoFrequencyAttr,
  },
  AutoPanner: {
    frequency: lfoFrequencyAttr,
  },
  Phaser: {
    frequency: lfoFrequencyAttr,
  },
  FrequencyShifter: {
    frequency: {
      min: -20000,
      max: 20000,
    },
  },
  PitchShift: {
    pitch: {
      min: -60,
      max: 60,
    },
  },
  BitCrusher: {
    bits: {
      type: "int",
      min: 1,
      max: 16,
    },
  },
  oscillator: {
    type: oscillatorTypeAttr,
    partials: {
      type: "enum",
      values: [[]],
    },
    partialCount: {
      type: "enum",
      values: [0],
    },
  },
  filter: {
    type: filterTypeAttr,
    rolloff: {
      type: "enum",
      values: [-12, -24, -48, -96],
    },
  },
};

const defaultAttribute = {
  min: 0,
  max: 1,
};

export function isBlacklisted(attrName) {
  return (
    ["urls", "channels", "channelCount", "partials", "partialCount"].find(
      (val) => val === attrName
    ) !== undefined
  );
}

export function hasAttribute(path) {
  return getAttribute(path) !== defaultAttribute;
}

export function getAttribute(path) {
  const nodes = path.split(".");
  function dfsSearch(node, index) {
    if (index === nodes.length) {
      return node;
    }
    if (!Object.prototype.hasOwnProperty.call(node, nodes[index])) {
      return null;
    }
    return dfsSearch(node[nodes[index]], index + 1);
  }
  for (let i = 0; i < nodes.length; i++) {
    const searchResult = dfsSearch(ParamsAttributes, i);
    if (searchResult) return searchResult;
  }
  return defaultAttribute;
}
