var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.track != null
    ? _c("div", { staticClass: "detail-panel-main" }, [
        _c(
          "div",
          { staticClass: "detail-panel-device-container" },
          [
            _c("DeviceControl", {
              key: _vm.track.uid,
              attrs: { device: _vm.track.instrument }
            }),
            _vm._v(" "),
            _vm._l(_vm.track.effects, function(effect, index) {
              return _c("DeviceControl", {
                key: _vm.track.uid + "." + effect + "." + effect.uniqueId,
                attrs: {
                  device: effect,
                  "device-index": index,
                  "total-device-count": _vm.track.effects.length
                },
                on: {
                  delete: _vm.handleDeleteEffect,
                  move: _vm.handleMoveEffect
                }
              })
            }),
            _vm._v(" "),
            _c(
              "StyledButton",
              {
                staticClass: "track-add-effect-button",
                on: { click: _vm.openMenu }
              },
              [_vm._v("Add Effect")]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "detail-panel-right-padding" })
          ],
          2
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }