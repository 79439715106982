var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "device-control-main" },
    [
      _c(
        "ParamsControl",
        {
          key: _vm.device.name,
          attrs: {
            title: _vm.device.name,
            options: _vm.options,
            path: _vm.device.name
          },
          on: { input: _vm.handleInput }
        },
        [
          _c(_vm.customComponent, {
            tag: "component",
            attrs: { device: _vm.device }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "device-meta-control" }, [
        _vm.deviceIndex !== undefined && _vm.deviceIndex !== 0
          ? _c(
              "button",
              {
                staticClass: "device-meta-button",
                on: {
                  click: function($event) {
                    return _vm.$emit("move", {
                      from: _vm.deviceIndex,
                      to: _vm.deviceIndex - 1
                    })
                  }
                }
              },
              [
                _c("img", {
                  attrs: { src: _vm.icons.playInvertIcon, alt: "left icon" }
                })
              ]
            )
          : _vm._e(),
        _vm.deviceIndex !== undefined &&
        _vm.deviceIndex !== _vm.totalDeviceCount - 1
          ? _c(
              "button",
              {
                staticClass: "device-meta-button",
                on: {
                  click: function($event) {
                    return _vm.$emit("move", {
                      from: _vm.deviceIndex,
                      to: _vm.deviceIndex + 1
                    })
                  }
                }
              },
              [
                _c("img", {
                  attrs: { src: _vm.icons.playIcon, alt: "right icon" }
                })
              ]
            )
          : _vm._e(),
        _vm.deviceIndex !== undefined
          ? _c(
              "button",
              {
                staticClass: "device-meta-button",
                on: {
                  click: function($event) {
                    return _vm.$emit("delete", _vm.deviceIndex)
                  }
                }
              },
              [_vm._v("\n      X\n    ")]
            )
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }