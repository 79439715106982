var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "knob-main", on: { mousedown: _vm.startDrag } },
    [
      _c("span", [
        _vm._v(
          _vm._s(
            !_vm.isDragging
              ? _vm.truncateString(_vm.propName)
              : typeof _vm.mappedValue === "number"
              ? _vm.mappedValue.toFixed(_vm.mappedValue >= 100 ? 0 : 1)
              : _vm.mappedValue
          )
        )
      ]),
      _vm._v(" "),
      _c("svg", [
        _c("path", {
          attrs: {
            d: _vm.backgroundPath,
            stroke: "#00ffff22",
            fill: "transparent",
            "stroke-width": "4px",
            "stroke-linecap": "round"
          }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d: _vm.fillPath,
            stroke: "#0ff",
            fill: "transparent",
            "stroke-width": "4px",
            "stroke-linecap": "round"
          }
        }),
        _vm._v(" "),
        _c("circle", {
          attrs: {
            cx: _vm.handleX,
            cy: _vm.handleY,
            r: "4",
            stroke: "#0ff",
            "stroke-width": "2px",
            fill: "#000"
          }
        })
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }