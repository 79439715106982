var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "params-control-wrapper" }, [
    _c("div", { staticClass: "params-control params-control-self" }, [
      _c("div", { staticClass: "params-title" }, [_vm._v(_vm._s(_vm.title))]),
      _vm._v(" "),
      _c("div", { staticClass: "self-container" }, [
        _c("div", { staticClass: "self-custom" }, [_vm._t("default")], 2),
        _vm._v(" "),
        _c("div", { staticClass: "self-default" }, [
          _c(
            "div",
            { staticClass: "upper" },
            _vm._l(_vm.upperItems, function(propName) {
              return _c(_vm.getWidget(propName), {
                key: _vm.path + "." + propName,
                tag: "component",
                attrs: {
                  title: _vm.path + "." + propName,
                  "prop-name": propName,
                  value: _vm.options[propName],
                  active: propName === _vm.childPropName,
                  "param-attributes": _vm.getParamAttributes(
                    _vm.path + "." + propName
                  )
                },
                on: { input: _vm.handleKnob, toggle: _vm.handleToggle }
              })
            }),
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "lower" },
            _vm._l(_vm.lowerItems, function(propName) {
              return _c(_vm.getWidget(propName), {
                key: _vm.path + "." + propName,
                tag: "component",
                attrs: {
                  title: _vm.path + "." + propName,
                  "prop-name": propName,
                  value: _vm.options[propName],
                  active: propName === _vm.childPropName,
                  "param-attributes": _vm.getParamAttributes(
                    _vm.path + "." + propName
                  )
                },
                on: { input: _vm.handleKnob, toggle: _vm.handleToggle }
              })
            }),
            1
          )
        ])
      ])
    ]),
    _vm._v(" "),
    _vm.childPropName !== undefined
      ? _c(
          "div",
          { staticClass: "params-control params-control-child" },
          [
            _c("ParamsControl", {
              key: _vm.path + "." + _vm.childPropName,
              attrs: {
                options: _vm.options[_vm.childPropName],
                root: _vm.childPropName,
                path: _vm.path + "." + _vm.childPropName,
                title: _vm.childPropName
              },
              on: { input: _vm.handleKnob }
            })
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }