/* eslint-disable prefer-rest-params */
import { Sampler, ToneAudioBuffer } from "tone";
import { optionsFromArguments } from "tone/Tone/core/util/Defaults";
import KickSample from "../assets/samples/707/kick.wav";
export class SHLMonoSampler extends Sampler {
    constructor() {
        super(optionsFromArguments(SHLMonoSampler.getDefaults(), arguments, ["urls", "onload", "baseUrl"], "urls"));
        this.name = "SHLMonoSampler";
        this.sample = "";
        this.sampleBuffer = new ToneAudioBuffer();
        this.sampleName = "";
        const options = optionsFromArguments(SHLMonoSampler.getDefaults(), arguments, ["urls", "onload", "baseUrl"], "urls");
        this.add(SHLMonoSampler.DEFAULT_NOTE, options.sample, undefined, options.sampleName);
    }
    set(props) {
        const prevSample = this.sample;
        super.set(props);
        if (prevSample != this.sample) {
            this.add(SHLMonoSampler.DEFAULT_NOTE, this.sample, undefined, this.sampleName);
        }
        return this;
    }
    static getDefaults() {
        return Object.assign(Sampler.getDefaults(), {
            sample: KickSample,
            sampleName: "Kick",
        });
    }
    triggerAttack(notes, time, velocity) {
        if (notes == "" || !this.sampleBuffer.loaded)
            return this;
        return super.triggerAttack(SHLMonoSampler.DEFAULT_NOTE, time, velocity);
    }
    triggerRelease(notes, time) {
        if (notes == "" || !this.sampleBuffer.loaded)
            return this;
        return super.triggerRelease(SHLMonoSampler.DEFAULT_NOTE, time);
    }
    triggerAttackRelease(notes, duration, time, velocity) {
        if (notes == "" || !this.sampleBuffer.loaded)
            return this;
        return super.triggerAttackRelease(SHLMonoSampler.DEFAULT_NOTE, "1n", time, velocity);
    }
    add(note, url, callback, sampleName) {
        if (sampleName)
            this.sampleName = sampleName;
        this.sample = url;
        this.sampleBuffer = new ToneAudioBuffer(url);
        super.add(SHLMonoSampler.DEFAULT_NOTE, this.sampleBuffer, callback);
        return this;
    }
}
SHLMonoSampler.DEFAULT_NOTE = "A1";
