import Vue from "vue";
export default Vue.extend({
    name: "Menu",
    props: {
        items: Array,
        anchor: null,
    },
    data() {
        return {
            menuDimensions: null,
        };
    },
    mounted() {
        this.menuDimensions = this.$refs.menuEl.getBoundingClientRect();
    },
    computed: {
        itemContainerStyle() {
            if (!this.anchor)
                return {};
            const rect = this.anchor.getBoundingClientRect?.() ?? {
                left: this.anchor.x,
                top: this.anchor.y,
            };
            const selfRect = this.menuDimensions ?? {
                width: 100,
                height: 100,
            };
            return {
                left: Math.min(window.innerWidth - selfRect.width, rect.left) + "px",
                bottom: window.innerHeight - Math.max(rect.top, selfRect.height) + "px",
            };
        },
    },
});
