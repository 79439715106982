import Vue from "vue";
export default Vue.extend({
    name: "Toggle",
    props: {
        propName: {
            type: String,
            default: "togg",
        },
        active: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        handleLeftClick() {
            this.$emit("toggle", this.propName);
        },
        truncateString(str) {
            if (str.length <= 5)
                return str;
            const lastChar = str.substr(-1, 1);
            if (lastChar >= "0" && lastChar <= "9") {
                return str.slice(0, 4) + lastChar;
            }
            return str.slice(0, 5);
        },
    },
});
