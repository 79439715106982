/* eslint-disable prefer-rest-params */
import { Effect } from "tone/build/esm/effect/Effect";
import { optionsFromArguments } from "tone/build/esm/core/util/Defaults";
import { EQ3, Compressor, Gain } from "tone";
export class SHLMixingUtilities extends Effect {
    constructor() {
        super(optionsFromArguments(SHLMixingUtilities.getDefaults(), arguments));
        this.name = "SHLMixingUtilities";
        const options = optionsFromArguments(SHLMixingUtilities.getDefaults(), arguments);
        this.eq3 = new EQ3(Object.assign(options.eq3, {
            context: this.context,
        }));
        this.compressor = new Compressor(Object.assign(options.compressor, {
            context: this.context,
        }));
        this.gain = new Gain(options.gain.gain, "decibels");
        console.log(this.gain);
        this.effectSend.chain(this.eq3, this.compressor, this.gain, this.effectReturn);
    }
    dispose() {
        this.eq3.dispose();
        this.compressor.dispose();
        this.gain.dispose();
        return super.dispose();
    }
    static getDefaults() {
        return Object.assign(Effect.getDefaults(), {
            eq3: EQ3.getDefaults(),
            compressor: Compressor.getDefaults(),
            gain: {
                convert: false,
                gain: 0,
                units: "decibels",
            },
        });
    }
}
