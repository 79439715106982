import Vue from "vue";
import WebDAW from "./components/WebDAW.vue";
import Menu from "./components/widgets/Menu.vue";
export default Vue.extend({
    name: "App",
    components: {
        WebDAW,
        Menu,
    },
    data() {
        return {
            menuItems: null,
            menuAnchor: this.$el,
            isMenuOpen: false,
            menuSelectCallback: null,
        };
    },
    methods: {
        callMenu(items, anchor, selectCallback) {
            this.menuItems = items;
            this.menuSelectCallback = selectCallback;
            this.isMenuOpen = true;
            this.menuAnchor = anchor;
            window.document.body.style.overflow = "hidden";
        },
        onMenuSelected(item) {
            this.menuSelectCallback?.(item);
            this.menuItems = null;
            this.menuSelectCallback = null;
            this.menuAnchor = this.$el;
            this.onDismiss();
        },
        onDismiss() {
            this.isMenuOpen = false;
            window.document.body.style.overflow = "auto";
        },
    },
    provide() {
        return { callMenu: this.callMenu.bind(this) };
    },
});
