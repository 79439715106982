var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mono-sampler-main" },
    [
      _c("div", { staticClass: "sample-title" }, [
        _vm._v("Current Sample: " + _vm._s(_vm.device.sampleName))
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "sample-display",
          on: { click: _vm.onSampleDisplayClicked }
        },
        [
          _c("canvas", {
            ref: "displayCanvas",
            attrs: { id: "sample-display-canvas", width: "480", height: "100" }
          }),
          _vm._v(" "),
          !_vm.isSampleLoaded
            ? _c("div", { staticClass: "sample-loading-placeholder" }, [
                _vm._v("\n      Loading...\n    ")
              ])
            : _vm._e()
        ]
      ),
      _vm._v(" "),
      _c(
        "StyledButton",
        {
          staticClass: "select-sample-button",
          on: { click: _vm.openSampleMenu }
        },
        [_vm._v("Select sample")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }