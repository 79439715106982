import Vue from "vue";
import * as Tone from "tone";
import Track from "./Track.vue";
import { InstrumentList } from "../configs/DeviceList";
import LeadPreset from "../SHLTone/presets/SHLSynthLead1";
import { LeadSequence, KickSequence } from "../SHLTone/sequences/TakeOnMe";
import TrackDetailPanel from "./TrackDetailPanel.vue";
import Piano from "./widgets/Piano.vue";
import StyledButton from "./widgets/StyledButton.vue";
import PlayIcon from "../assets/icons/play.svg";
import StopIcon from "../assets/icons/stop.svg";
import RecordIcon from "../assets/icons/record.svg";
import RecordInvertIcon from "../assets/icons/recordInvert.svg";
import { clamp } from "../utils/SHLMath";
export default Vue.extend({
    name: "WebDAW",
    components: {
        StyledButton,
        TrackDetailPanel,
        Track,
        Piano,
    },
    data() {
        return {
            isTransportPlaying: false,
            recorder: new Tone.Recorder(),
            isRecording: false,
            transport: Tone.Transport,
            bpm: 86,
            icons: {
                play: PlayIcon,
                stop: StopIcon,
                record: RecordIcon,
                recordInvert: RecordInvertIcon,
            },
            selectedTrackInfo: null,
            projectData: [
                {
                    initOptions: {
                        name: "Lead",
                        uid: -1,
                        instrument: {
                            name: "SHLSynth",
                            data: Object.assign({ volume: -12 }, LeadPreset),
                        },
                        effects: [],
                        sequence: LeadSequence,
                    },
                },
                {
                    initOptions: {
                        name: "Kick",
                        uid: -2,
                        instrument: {
                            name: "SHLMonoSampler",
                        },
                        effects: [],
                        sequence: KickSequence,
                    },
                },
            ],
            uidCounter: 0,
            pianoContext: null,
            pressedKey: "",
            clipboard: null,
        };
    },
    inject: ["callMenu"],
    provide() {
        return {
            getClipboard: this.getClipboard,
            setClipboard: this.setClipboard,
            addTrackAsNew: function (trackOptions) {
                this.projectData.push({
                    initOptions: {
                        ...trackOptions,
                        uid: this.uidCounter++,
                    },
                });
            }.bind(this),
            requestNewTrackUid: function () {
                return this.uidCounter++;
            }.bind(this),
        };
    },
    methods: {
        initAudio() {
            Tone.start();
            this.transport.swingSubdivision = "16n";
        },
        toggleTransport() {
            this.isTransportPlaying = !this.isTransportPlaying;
            if (this.isTransportPlaying) {
                Tone.Transport.start();
            }
            else {
                Tone.Transport.pause();
            }
        },
        toggleRecord() {
            if (!this.isRecording) {
                this.recorder.start();
                this.transport.position = 0;
            }
            else {
                this.recorder.stop().then((blob) => {
                    const url = URL.createObjectURL(blob);
                    const anchor = document.createElement("a");
                    anchor.download = "recording.webm";
                    anchor.href = url;
                    anchor.click();
                });
            }
            this.isRecording = !this.isRecording;
            if (this.isTransportPlaying != this.isRecording)
                this.toggleTransport();
        },
        setBpm() {
            this.bpm = clamp(+this.bpm, 1, 300);
            if (isNaN(this.bpm))
                this.bpm = 120;
            Tone.Transport.set({
                bpm: this.bpm,
            });
        },
        onTrackFocus(event) {
            this.selectedTrackInfo = event;
        },
        onTrackDelete(targetUid) {
            if (this.selectedTrackInfo && this.selectedTrackInfo.uid == targetUid)
                this.selectedTrackInfo = null;
            console.log(targetUid);
            this.projectData = this.projectData.filter((trackRecord) => trackRecord.initOptions.uid !== targetUid);
        },
        openAddNewTrackMenu(event) {
            this.callMenu(Object.keys(InstrumentList), event.target, (item) => this.onAddNewTrackMenuSelected(item));
        },
        onAddNewTrackMenuSelected(item) {
            this.projectData.push({
                initOptions: {
                    name: item,
                    uid: this.uidCounter++,
                    instrument: {
                        name: item,
                    },
                },
            });
        },
        onTrackCreated({ track, uid }) {
            this.projectData.find((trackRecord) => trackRecord.initOptions.uid === uid).instance = track;
            this.selectedTrackInfo = {
                track: track,
                uid: uid,
            };
        },
        evokePiano(pianoContext) {
            this.pianoContext = pianoContext;
            this.pressedKey = "";
        },
        dismissPiano() {
            this.pianoContext = null;
        },
        clearProject() {
            this.projectData = [];
            this.uidCounter = 0;
            this.selectedTrackInfo = null;
            this.bpm = 86;
            this.setBpm();
            this.transport.swing = 0;
            this.transport.position = 0;
        },
        exportProject() {
            const ret = {};
            ret.bpm = this.bpm;
            ret.swing = this.transport.swing;
            ret.tracks = [];
            for (let trackRecord of this.projectData) {
                ret.tracks.push(trackRecord.instance?.toOptions());
            }
            console.log(ret);
            const str = JSON.stringify(ret);
            console.log(str);
            window.navigator.clipboard.writeText(str).then(() => {
                alert("Project data copied to clipboard!");
            });
        },
        importProject() {
            const loadedData = JSON.parse(prompt("enter data"));
            console.log(loadedData);
            if (!loadedData)
                return;
            this.clearProject();
            this.$nextTick(() => {
                try {
                    this.bpm = loadedData.bpm;
                    this.setBpm();
                    this.transport.swing = loadedData.swing ?? 0;
                    loadedData.tracks.map((trackOptions) => {
                        this.projectData.push({ initOptions: trackOptions });
                        this.uidCounter = Math.max(this.uidCounter, trackOptions.uid + 1);
                    });
                }
                catch (e) {
                    console.log("Error when importing project: ", e);
                    this.clearProject();
                }
            });
        },
        setClipboard(obj) {
            this.clipboard = obj;
        },
        getClipboard() {
            return this.clipboard;
        },
    },
    created() {
        this.setBpm();
        Tone.Destination.connect(this.recorder);
    },
});
