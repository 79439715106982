import Vue from "vue";
import { Note } from "tone/build/esm/core/type/NoteUnits";
export default Vue.extend({
    name: "SequencerCell",
    props: {
        mono: {
            type: Boolean,
            default: false,
        },
        value: {
            type: Note,
            default: "F#11",
        },
        instrument: Object,
    },
    data() {
        return {
            isFocused: false,
        };
    },
    methods: {
        handleLeftClick(event) {
            event.preventDefault();
            if (event.button == 2) {
                // Right click: Clear
                this.$emit("input", "");
                this.$emit("update");
                return;
            }
            if (this.mono) {
                this.$emit("input", "C1"); // dummy value
                this.$emit("update");
            }
            else if (!this.isFocused) {
                this.isFocused = true;
                this.$emit("evoke-piano", {
                    activatingMouseEvent: event,
                    currentNote: this.value,
                    instrument: this.instrument,
                    onSelected: (note) => {
                        this.$emit("input", note);
                        this.$emit("update");
                    },
                    onCancel: () => {
                        this.isFocused = false;
                    },
                });
            }
        },
        handleRightClick(event) {
            event.preventDefault();
            this.$emit("input", "");
            this.$emit("update");
        },
    },
});
